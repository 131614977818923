import React, { useState, useContext } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { Section, Input, Button } from "../components/Core";
import axios from 'axios';
import styled from "styled-components";
import GlobalContext from './../context/GlobalContext';
import eyeIcon from './../assets/image/rebrand/svg/eye-icon.svg';
import FullScreenLoader from '../components/FullScreenLoader';
import { navigate } from 'gatsby';
import Helmet from "react-helmet"
import API from '../api/loginApi';

const AbsoluteImage = styled.img`
  position: absolute;
  top: 13px;
  right: 2rem;
  opacity: 0.7;
  &:hover {
    cursor: pointer;
    opacity: 1;
  }
`

const ResetPassword = () => {

  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const [showPass, setShowPass] = useState(false);
  const [isCreatingRecords, setIsCreatingRecords] = useState(false);
  const gContext = useContext(GlobalContext);

  const submitForm = () => {
    gContext.showFullScreenLoader();
    setIsCreatingRecords(true)
    
    API.post('/IdentityModule/v1.0/users/login', {
      email: emailAddress,
      password: password
    }).then((response) => {
      const currentTimestamp = Math.floor(Date.now() / 1000);
      const expiryTimestamp = currentTimestamp + response.data.expiresIn;
      localStorage.setItem('agentToken', response.data.token)
      localStorage.setItem('agentEmail', emailAddress)
      localStorage.setItem('agentTokenExpiration', expiryTimestamp)
      setIsCreatingRecords(false)
      gContext.setShowAgentBanner(true)
      navigate('/check-availability')
      gContext.hideFullScreenLoader();
    }).catch((error) => {
      gContext.hideFullScreenLoader();
      setIsCreatingRecords(false)
      gContext.setAPIErrorMsg({
        title: 'Login error',
        message: error.response ? error.response?.data.message : error
      });
    })
  }

  return (
    <>
      <FullScreenLoader title="Processing request ..." />
      <Helmet>            
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Section>
        <Container>
          <Row className='centered'>
            <Col lg={8} md={10} sm={8} className='centered text-center'>
              <h3>Agent Login</h3>
              <Row className='text-center mt-3'>
                <Col sm={12} lg={6} className="mt-3 centered">
                <Input
                    type=""
                    name="email"
                    disabled={isCreatingRecords}
                    autocomplete="email"
                    placeholder="Email address"
                    value={emailAddress}
                    onChange={e => setEmailAddress(e.target.value)}
                    css={isCreatingRecords ? 'opacity: 0.3' : undefined}
                  />
                </Col>
              </Row>
              <Row className='text-center'>
                <Col sm={12} lg={6} className="mt-3 centered">
                  <Input
                    type={showPass ? "text" : "password"}
                    name="password"
                    disabled={isCreatingRecords}
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    css={isCreatingRecords ? 'opacity: 0.3' : undefined}
                  />
                  <AbsoluteImage src={eyeIcon} onClick={() => setShowPass(!showPass)}/>
                </Col>
              </Row>

              <Button
                onClick={submitForm}
                className="medium-button mt-5 blue-bg"
                disabled={!emailAddress || !password}
              >
                Login
              </Button>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  )
}
export default ResetPassword;

